/* Live */
export const appId = "EF1AE173-3BB1-4B8E-88DB-183FF8EF904B";
export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const baseUrl = "https://www.mrbiryanisg.com/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";


// export const apiUrl = "https://ccpl.promobuddy.asia/api/";
// export const apiUrlV2 = "https://ccpl.promobuddy.asia/apiv2/";
// export const apiUrlPro = "https://ccpl.promobuddy.asia/ninjapro/";
// export const apiUrlNotify = "https://ccpl.promobuddy.asia/Pushorder/";
// //export const baseUrl = "http://localhost:3000/";
// export const baseUrl = "https://mrbiryani.promobuddy.asia/";
// export const timThumpUrl = "https://ccpl.promobuddy.asia/timthumb.php?src=";
// export const mediaUrl = "https://ccpl.promobuddy.asia/media/dev_team/";
// export const blogImageUrl = "https://ccpl.promobuddy.asia/media/dev_team/blog/";
// export const tagImageUrl = "https://ccpl.promobuddy.asia/media/dev_team/tag/";
// export const proFolderUrl = "https://ccpl.promobuddy.asia/media/dev_team/products/";

export const stripeImage = "/img/stripe_logo.png";
export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const googleAppId = "184164322457-e5pd7mn42sr82akm1dktt0mlt3qd9o18.apps.googleusercontent.com";
  
export const fbAppId = "909195839838106";
export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Mr Biryani";
export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "mrbiryani";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const companyname = "Mr Biryani";

