/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
import Slider from "react-slick";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import { deliveryId, reservationId } from "../Helpers/Config";
import { showLoader } from "../Helpers/SettingHelper";
import MenuNavigationMobile from "./MenuNavigationMobile";

import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ZONE_DETAIL,
  GET_PROMOTIONLIST,
} from "../../actions";
import notifyimage from "../../common/images/nophone.png";
import notifywhiteimage from "../../common/images/nophone-white.png";

const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");

var settingsNotify = {
  infinite: true,
  dots: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 2,
  speed: 400,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      productload: "Yes",
      promotions: [],
      display_Promo: "",
      showProductDetails: "N",
      closepromo: "N",
      cartItem: [],
    };

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return;
    }


    this.props.getSettings();
    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
    this.props.getMenuNavigationList();
    var customerParam = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      customerParam = "&customer_id=" + cookie.load("UserId");
    }
    this.props.getPromotionList(customerParam);
  }

  componentWillReceiveProps(nextProps) {
    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        setTimeout(function () {
          $(window).scrollTo($("." + proValue), 100);
        }, 2000);
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {
      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? ''
            : nextProps.selectedCatry;
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        navigateMenu: nextProps.navigateMenu,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
      });
    }
    if (nextProps.promotionlist !== this.props.promotionlist) {
      if (nextProps.promotionlist[0].status === "ok") {
        this.setState({ status: nextProps.promotionlist[0].status });
        this.setState(
          {
            promotions: nextProps.promotionlist[0].result_set.my_promo,
          },
          function () {
            this.displayPromo();
          }
        );
      }
    }
  }

  componentDidMount() {

    var orderAvailabiltyReservation = cookie.load("defaultAvilablityId");
    if (orderAvailabiltyReservation === reservationId) {
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return;
    }
    
    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
    $(".product_search_result").hide();


  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }

    if (field === "cartItem" && value !== "") {
      this.setState({ cartItem: value });
    }

    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    }
    if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    }
    if (field === "resetProduct" && value !== "") {
      this.setState({
        viewProductSlug: "",
      });
    }
    if (field === "closepromo" && value !== "") {
      this.setState({ closepromo: value });
    }
    if (field === "showProductDetails" && value !== "") {
      this.setState({ showProductDetails: value, selectedProSlug: "" });
    }
  };

  displayPromo() {
    if (this.state.promotions.length > 0) {
      let displayPromo = this.state.promotions.map(function (item, index) {
        return (
          <div className="notify-box" key={index}>
            <div className="notify-image">
              <img src={notifyimage} className="no_mou" />
              <img src={notifywhiteimage} className="mou_active" />
            </div>
            <div className="notify-cnt">
              <h5>
                {item.promotion_title !== ""
                  ? Parser(item.promotion_title)
                  : item.promo_code}
              </h5>
              {item.promo_desc !== "" ? Parser(item.promo_desc) : ""}
            </div>
            <span class="notify_circle"></span>
          </div>
        );
      });
      this.setState({ display_Promo: displayPromo });
    }
  }

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    this.setState({ showProductDetails: "Y" });
    /* { backdrop: "static", keyboard: false } */
    //$("#ProductDetailMdl").modal();
  }

  handleChange(section, event) {
    let filterTag = section.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    section.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }

  render() {
    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
        />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h3>Our Menu</h3>
          </div>
        </div>

        <section className="menu-nav-section">
          <div className="product-menu">
            <MenuNavigation
              {...this.props}
              productState={this.state}
              sateValChange={this.sateValChange}
            />
          </div>
        </section>

         <section className="product-menu-listing product-menu-mobile">
            <MenuNavigationMobile
              {...this.props}
              productState={this.state}
              sateValChange={this.sateValChange}
            />
        </section> 

        {this.state.display_Promo !== "" && this.state.closepromo === "N" && (
          <div className="notification-pg">
            <div className="notification-pg-box">
              <div className="notification-pg-slider">
                <div className="container">
                  <div className="notify-row">
                    <Slider {...settingsNotify}>
                      {this.state.display_Promo}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Header End */}

        <section className="product-menu-listing">

          <div className="container">
            <ProductDetail
              productState={this.state}
              sateValChange={this.sateValChange}
            />

            <ProductList
              {...this.props}
              cartTriggerFlg={this.state.cartTriggerFlg}
              productState={this.state}
              sateValChange={this.sateValChange}
            />
          </div>
        </section>

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var promoSource = "";
  if (Object.keys(state.promotionlist).length > 0) {
    promoSource = state.promotionlist[0].common.promo_image_source;
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
    promotionlist: state.promotionlist,
    promoSource: promoSource,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
